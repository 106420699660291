<template>
  <div class="custom-bg-secondary">
    <b-breadcrumb class="container mx-auto" style="border-radius: 0">
      <b-breadcrumb-item :to="{ name: 'Home' }">
        <!-- <b-icon icon="house-fill" scale="1.25" shift-v="1.25" aria-hidden="true"></b-icon> -->
        {{ $t("Footer.Footer.Buyers") }}
      </b-breadcrumb-item>
      <b-breadcrumb-item active>{{ $t("SalesRepresentatives.SalesRepresentatives") }}</b-breadcrumb-item>
    </b-breadcrumb>

    <div class="px-0 custom-bg-secondary">
      <b-jumbotron class="text-white headBanner headerBG side_distance">
        <b-container class="row align-items-end mx-auto">
          <div class="col-12 col-lg-8 p-0">
            <h1 class="headerH1 text-left text-white">
              {{ $t("SalesRepresentatives.FindSalesRepresentative") }}
              <!-- <span v-html="story"></span> -->
            </h1>
            <div class="my-5">
              <h5 class="subtitle">
                {{ $t("SalesRepresentatives.SalesRepresentativesDescription") }}
              </h5>
            </div>

            <div class="text-left text-secondary w-100 w-md-50 sales-representative">
              <b-row>
                <b-col>
                  <b-form-select
                    class="countrySelect"
                    v-model="searchCountry"
                    @change="getRepList()"
                  >
                    <b-form-select-option disabled :value="null">
                      {{ $t("dashboard.label.pleaseSelectACountry") }}
                    </b-form-select-option>
                    <b-form-select-option
                      v-for="country in buyerCountries"
                      :value="country"
                      :key="country.Code"
                    >
                      {{ country.Name }}
                    </b-form-select-option>
                  </b-form-select>
                </b-col>
                <!-- <b-col style="max-width:150px">
                  <b-button
                    variant="warning"
                    class="px-4"
                    @click="getRepList()"
                  >
                    Search
                  </b-button>
                </b-col> -->
              </b-row>
            </div>
          </div>
        </b-container>
      </b-jumbotron>
      <SaleRepList :repLists="repLists" :searchCountry="searchCountry ? searchCountry.Name : ''"/>
      <div class="text-center mt-4 interested-box">
        <h6>
          {{ $t("SalesRepresentatives.SalesRepresentativesDesc1") }}
          <b-link :to="{ name: 'Contact', params: { type: 'sales-representatives' } }">
            {{ $t("SalesRepresentatives.LearnMore") }}
          </b-link>
        </h6>
      </div>
    </div>
  </div>
</template>

<script>
import repList from "@/components/mixins/repList.js"
import SaleRepList from "./SaleRepList.vue";

export default {
  data() {
    return {
      searchCountry: null,
    };
  },
  mixins: [repList],
  components: {
    SaleRepList
  },
  computed: {
    buyerCountries() {
      return this.$store.getters.activeBuyerCountries;
    },
  }
};
</script>

<style scoped lang="scss">
.headerBG {
  background-image: url("~@/assets/img/salesRepresentative_bg.jpg");
  background-color: white;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
}

.side_distance {
  padding: 0;
  border-radius: 0;
  margin-bottom: 0;
  height: 450px;
  @media (max-width: 575px) {
    height: 250px;
  }
}
.headerH1 {
  margin-top: 80px;
  font-size: 40px;
  line-height: 56px;
  margin-bottom: 48px;
  max-width: 580px;
  @media (max-width: 767px) {
    font-size: 35px;
    line-height: 48px;
  }
  @media (max-width: 575px) {
    font-size: 24px;
    line-height: 32px;
    max-width: 100%;
    margin-top: 24px;
    margin-bottom: 16px;
    font-weight: 500;
  }
}
.subtitle {
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
  max-width: 580px;
  font-family: "Inter", sans-serif !important;
  @media (max-width: 575px) {
    max-width: 100%;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
  }
}
.countrySelect {
  background-color: white !important;
  border: none;
}
@media (min-width: 1670px) {
  .headerBG {
    background-size: 100%;
  }
}

@media (max-width: 1200px) {
  .headerBG {
    background-position: 55% 0%;
    background-size: auto;
  }
  .side_distance {
    padding: 0 0 50px 0;
  }
}

@media (max-width: 992px) {
  .headerBG {
    background-position: 60% 0%;
  }
}
@media (max-width: 768px) {
  .headerBG {
    background-position: 55% 0%;
  }
}

@media (max-width: 576px) {
  .headerBG {
    background-position: 68% 0%;
    background-size: auto 100%;
  }
}
.interested-box {
      background: #e9ecef;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 15px;
      @media (max-width: 575px) {
        height: 100px;
      }
      h6 {
        margin-bottom: 0;
        font-weight: 400;
        font-size: 16px;
        @media (max-width: 575px) {
          font-size: 14px;
          line-height: 20px;
        }
      }
    }

</style>

<style lang="scss">
.sales-representative {
  .countrySelect {
    width: 400px !important;
    height: 48px;
    @media (max-width: 575px) {
      max-width: 280px;
    }
  }
}
</style>

import axios from "axios";

export default {
    data() {
        return {
            repLists: [],
        }
    },
    methods: {
        async getRepList(country) {
          try {
            const respones = await axios.post("user/SalesRepList", {
              CountryCode: country ? country.Code : this.searchCountry.Code,
            });
            this.repLists = respones.data;
          } catch (error) {
            error;
          }
        },
      },
}
<template>
  <div>
    <b-container class="find-container" :class="isPopUp ? 'px-0' : ''">
        <h5 :class="isPopUp ? 'font-weight-normal' : 'find-title'" :style="isPopUp ? 'font-size:1.3em;' : ''">
          <span v-if="repLists.length <= 0 && !searchCountry">{{ $t("SalesRepresentatives.NotFound") }}</span>
          <span v-else v-html='$t("SalesRepresentatives.Found",[repLists.length,searchCountry])' />
        </h5>
        <div class="founded-wrapper" :class="isPopUp ? '' : 'founded-wrapper-not-popup'" v-if="repLists">
          <TheSalesAgents :agents="agent" v-for="agent in repLists" :key="agent.name" />
        </div>
      </b-container>
  </div>
</template>

<script>
import TheSalesAgents from "@/components/reservation/TheSalesAgentCard";

export default {
    props: {
        repLists: {
            type: Array,
            required: true
        },
        searchCountry: {
            required: true
        },
        isPopUp: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    components: {
            TheSalesAgents,
        },
}
</script>

<style lang="scss">
    .find-title {
      font-size: 24px;
      font-weight: 500;
      line-height: 32px;
      margin-top: 64px;
      margin-bottom: 24px;
      @media (max-width: 767px) {
        margin-top: 32px;
      }
      @media (max-width: 575px) {
        margin-top: 16px;
        font-size: 16px;
        line-height: 24px;
      }
    }
    .find-container {
      padding-bottom: 64px;
    }
.founded-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  column-gap: 20px;
  row-gap: 24px;
  .agentCard {
    width: 100%;
    min-height: 250px;
    border: 1px solid #d0d0d0 !important;
    margin: 0 !important;
    transition: 0.3s;
    background: white !important;
    &:hover {
      box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.15);
    }
    }
}

.founded-wrapper-not-popup {
  .agentCard {
    @media screen and (min-width: 1024px) {
      width: calc((100% / 2) - 10px)!important;
    }
  }
}
</style>